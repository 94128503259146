.resume__container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.875rem;
}

.timeline {
    background-color: var(--container-color);
    padding: 1.875rem;
    border-radius: var(--border-radius);
    position: relative;
    box-shadow: var(--shadow);
}

.timeline__item {
    position:relative;
    padding-left: 3.125rem;
    padding-bottom: 3.125rem;
}

.timeline__item:last-child {
    padding-bottom: 0;
}

.timeline__item::before {
    content: '';
    width: 1px;
    height: 100%;
    background-color: var(--first-color);
    position: absolute;
    left: .25rem;
    top: 0;
}

.timeline .icon-briefcase,
.timeline .icon-graduation {
    position: absolute;
    left: -0.4375rem;
    top: 0;
    font-size: var(--h2-font-size);
    color: var(--first-color);
    background-color: var(--container-color);
    padding: 0.4375rem 0;
}

.timeline__date {
    color: #8b8bb1;
    font-size: var(--small-font-size);
}

.timeline__title {
    font-size: var(--h3-font-size);
    margin: 0.5rem 0;
}

@media screen and (max-width: 1024px){
    .resume__container {
        grid-template-columns: 450px;
        justify-content: center;
        row-gap: 1.875rem;
    }

    .timeline__item::before {
        left: 2px;
    }
}

@media screen and (max-width: 576px){
    .resume__container {
        grid-template-columns: 310px;
    }
}

@media screen and (max-width: 350px){
    .timeline__item {
        padding-left: 1.875rem;
        padding-bottom: 1.875rem;
    }

    .timeline__item::before {
        left: 0;
    }

    .timline .icon-briefcase,
    .timeline .icon-graduation {
        left: -9px;
    }

    .timeline__text {
        text-align: justify;
    }
}